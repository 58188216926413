import React from 'react'
import Seo from '../../components/SEO'
import styled from '@emotion/styled'

import Layout from '../../components/layout'
import { colors, breakpoints, container } from '../../styles/theme'
import PageBody from '../../components/shared/page-body'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import GGVideo from '../../components/gareth-gates-event-video'

const GarethGatesEventPage = () => {
  const { dinnerJacketPortrait } = useStaticQuery(graphql`
    {
      dinnerJacketPortrait: file(
        relativePath: { eq: "img/gareth-gates-portrait-dinner-jacket.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 720) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="An Intimate Evening with Gareth Gates"
        pathname="/events/gareth-gates-march-2020/"
      />
      <EventsPageBody>
        <div style={{ padding: '24px 0' }}>
          <GGVideo />
        </div>

        <IntroContainer>
          <h1 className="heading--h2" style={{ marginTop: 0 }}>
            An Intimate Evening with Gareth Gates
            <br />
            <span style={{ fontSize: '.75em' }}>
              12<sup>th</sup> March 2020 — The Cumberland Hotel
            </span>
          </h1>
          <p>
            Thank you to everyone who attended our Intimate Evening with Gareth
            Gates!
            <br />
            We look forward to seeing you at future Fox Tailoring events.
          </p>
          <EventProgramme />
          <Image
            {...dinnerJacketPortrait.childImageSharp}
            alt="Gareth Gates Tailored Dinner Jacket"
            style={{
              margin: '0 auto',
              flexGrow: 1,
              maxHeight: 720,
              minWidth: 200,
            }}
            imgStyle={{
              objectFit: 'contain',
            }}
          />
        </IntroContainer>
      </EventsPageBody>
    </Layout>
  )
}

const EventsPageBody = styled(PageBody)`
  padding: 0 0 60px;
  background: linear-gradient(${colors.black}, #000 540px);
  background-size: cover;
  color: #fff;

  @media (min-width: ${breakpoints.large}px) {
    padding-top: 1px;
  }

  hr {
    border-color: ${colors.darkGrey};
  }
`

const IntroContainer = styled.div`
  ${container};
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
`

const EventProgramme = () => {
  return (
    <section>
      <h3 className="heading heading--h4" style={{ marginTop: 32 }}>
        Event Programme
      </h3>
      <ScheduleList>
        <li>
          <span className="scheduleTime">6.00 - 7.00pm</span> Oceana Suite, The
          Cumberland Hotel
          <ul>
            <li>Guest Arrival</li>
            <li>Pre-Dinner Prosecco/Fruit Juice</li>
            <li>Meet and Greet Photographs with Gareth Gates</li>
            <li>
              Pre-Dinner Entertainment from Local Singer/Songwriter Paul Kinvig
            </li>
          </ul>
        </li>
        <li>
          <span className="scheduleTime">7.00 - 8.15pm</span> Mirabelle Suite
          <ul>
            <li>Dinner Service in Mirabelle Restaurant</li>
            <li>
              2-course dinner with half a bottle of wine per person.
              <br /> Please see below for menu choices and wine.
            </li>
          </ul>
        </li>
        <li>
          <span className="scheduleTime">8.30 - 9.00pm</span> Adele Tribute act
          Mirabelle Restaurant
        </li>
        <li>
          <span className="scheduleTime">9.00pm</span> Main Entertainment -
          Mirabelle Restaurant
          <ul>
            <li>Cocktails &amp; Tailcoats Fashion Show</li>
            <li>Duet Gareth Gates / Adele</li>
            <li>Personal Performance by Gareth Gates</li>
            <li>Disco</li>
          </ul>
        </li>
      </ScheduleList>
    </section>
  )
}

const ScheduleList = styled.ol`
  list-style: none;
  padding: 0;

  > li {
    margin-top: 32px;
  }

  .scheduleTime {
    font-weight: 500;
  }
`

export default GarethGatesEventPage
